<template>
  <div>
    <CDropdownItem
      :disabled="disabled || $route.name === route"
      @click="handleClick"
    >
      <div v-if="icon" class="icon row align-items-center">
        <div class="col d-flex">
          <CIcon class="mr-2 mt-1" :name="icon" />
          <span v-if="label">
            {{ label }}
          </span>
          <span>
            {{ actualUserLabel }}
          </span>
          <div class="settings-body">
            <slot />
          </div>
        </div>
      </div>
    </CDropdownItem>
  </div>
</template>
<script>
const ACTIONS = {
  logout: "user/logout",
};
export default {
  name: "SettingsDropdownItem",
  props: {
    label: {
      type: String || Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userLabel: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    route: {
      type: String,
      default: null,
      required: false,
    },
    action: {
      type: String,
      default: null,
      validator: function (value) {
        return Object.keys(ACTIONS).indexOf(value) !== -1;
      },
    },
  },
  computed: {
    actualUserLabel() {
      if (this.userLabel) {
        return `User: ${this.$store.state.user.user.name}`;
      } else {
        return null;
      }
    },
  },
  methods: {
    handleClick() {
      if (this.action) {
        this.doAction(this.action);
      } else if (this.route) {
        this.doRoute(this.route);
      } else {
        return;
      }
    },
    doAction(action) {
      this.$store.dispatch(ACTIONS[action]);
    },
    doRoute(route) {
      this.$router.push({ name: route }, { query: { ...this.$route.query } });
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-dropdown-item {
  // height: 50px;
  // padding: 0;
  // margin: 0;
}
</style>

.
<template>
  <div class="notification-settings">
    <div v-if="displayType !== 'noDropdown'" class="default">
      <CDropdown toggler-text="">
        <CDropdownHeader>Recieve Notifications by:</CDropdownHeader>
        <template #toggler>
          <button class="c-header-nav-btn settings-button">Settings</button>
        </template>
        <CDropdownItem v-for="(service, index) in notificationServices" :key="index" class="p-2">
          <div class="notification-settings-item justify-content-between w-100 d-flex align-items-center" @click.stop>
            <p>{{ service.name }}:</p>
            <span class="notification-toggle">
              <label class="switch">
                <input :name="service.name" @change="handleChange" type="checkbox" v-model="service.checked" />
                <span class="slider beans"></span>
              </label>
            </span>
          </div>
        </CDropdownItem>
      </CDropdown>
    </div>
    <div v-else class="no-dropdown">
      <CDropdownItem v-for="(service, index) in notificationServices" :key="index" class="p-2">
        <div class="notification-settings-item justify-content-between w-100 d-flex align-items-center" @click.stop>
          <p>{{ service.name }}:</p>
          <span class="notification-toggle">
            <label class="switch">
              <input :class="sliderChecked(service)" :name="service.name" @change="handleChange" type="checkbox" v-model="service.checked" :disabled="!service.enabled" />
              <span class="slider round"></span>
            </label>
          </span>
        </div>
      </CDropdownItem>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotificationSettings",
  props: {
    displayType: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      notificationServices: [
        {
          name: "Slack",
          checked: false,
          // field to match the name on the backend
          service: "slack",
          enabled: true
        },
        {
          name: "SMS",
          checked: false,
          service: "sms",
          enabled: false
        },
        {
          name: "Email",
          checked: false,
          service: "email",
          enabled: false
        }
      ]
    }
  },
  mounted() {
    this.populateServices()
  },
  computed: {
    
  },
  methods: {
    async populateServices() {
      if (this) console.log("This is america")
      //TODO: remove the hardcode
      var email = this.email || "winer.harry@gmail.com"

      // var response = await axios.get(url, {headers: {"Authorization": `Bearer ${accessToken}`}})
      //   .catch(err => {console.error(err)})

      var response = await this.$http.get(`/notifications/services/${email}`).catch((err) => {
        console.error(err)
      })
      const services = response.data
      this.notificationServices.map((serviceSettings) => {
        // if the service is in services then set checked to enabled
        const serviceName = serviceSettings.service
        if (serviceName in services) {
          serviceSettings.checked = services[serviceName].enabled
        }
      })
    },
    handleChange(data) {
      console.log("change working")
      if (data.target.checked === true) {
        const redirect = encodeURIComponent(window.location.href)
        const accessToken = localStorage.getItem("accessToken")

        if (this.email && data.target.name === "Slack") {
          console.log("backend: " + process.env.VUE_APP_API_BASE)
          const url =
            (process.env.VUE_APP_API_BASE || `http://localhost:5000/`) +
            `notifications/auth/slack?accessToken=${accessToken}&user=${this.email}&redirect=${redirect}`
          window.open(url, "_self")
        }
      } else {
        //TODO: whatever to unsubscribe from setting
        var url = (process.env.VUE_APP_API_BASE || `http://localhost:5000/`) + `notifications/services/${this.email}`
        console.log("email: ", this.email)

        if (data.target.name === "Slack") {
          var serviceName = "slack"
        } else {
          var serviceName = null
        }
        this.$http
          .put(url, { serviceName, enabled: false })
          .then(() => {
            console.log("Service updated")
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    sliderChecked(service) {
      // if the bar is both not checked and not disabled then give it a class 
      if (service.enabled && !service.checked) {
        return "notCheckedEnabled"
      }
    }
  }
}
</script>
<style scoped>


a.p-2 {
  pointer-events: none;
  box-shadow: none;
  padding: 0rem !important;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: inherit;
  color: inherit;
}

.settings-button {
  padding: 0;
  /* margin: 0; */
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.5);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

label.switch {
  pointer-events:auto;
}

input:checked + .slider {
  background-color: #2196f3;
}

input.notCheckedEnabled + .slider {
  background-color: #f37e21;
  
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:disabled {
  background-color: #ccc;
  opacity: .3
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.mt2 {
  padding: 5px 0px 0px 0px
}
</style>
